//#region Imports

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { Network } from '@ionic-native/network/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { httpAsyncFactory } from './factory/http-async.factory';
import { RetryInterceptor } from './interceptors/retry.interceptor';
import { createErrorHandler } from './sentry/error-handler';
import { TraceService } from './sentry/tracing';
import { HelperService } from './services/helper/helper.service';
import { HttpAsyncService } from './services/http-async.service.ts/http-async.service';
import { StorageService } from './services/storage/storage.service';
import { SwiperModule } from 'swiper/angular';
import { pt_BR, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { registerLocaleData } from '@angular/common';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import localePt from '@angular/common/locales/pt';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TabDirective } from 'src/app/pipes/tab/tab.directive';

//#endregion

registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent, TabDirective],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    SwiperModule,
    NzNotificationModule,
    NzIconModule,
    NzSpinModule,
    IonicStorageModule.forRoot({
      name: '__nova_aula_v2',
      driverOrder: ['indexeddb', 'sqlite', 'websql'],
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    HelperService,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: NZ_I18N, useValue: pt_BR },
    {
      provide: HttpAsyncService,
      useFactory: httpAsyncFactory,
      deps: [HttpClient, HelperService, StorageService],
    },
    Network,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
        logErrors: !environment.production,
        dialogOptions: environment.sentry.feedback,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
