//#region Imports

import { Injectable } from '@angular/core';
import { RegisterPayload } from 'src/app/models/payloads/register.payload';
import { UserPayload } from 'src/app/models/payloads/user.payload';
import { ForgotPasswordProxy } from 'src/app/models/proxies/forgot-password.proxy';
import { environment } from '../../../environments/environment';
import { LoginPayload } from '../../models/payloads/login.payload';
import { TokenProxy } from '../../models/proxies/token.proxy';
import { UserProxy } from '../../models/proxies/user.proxy';
import {
  AsyncResult,
  HttpAsyncService,
} from '../../services/http-async.service.ts/http-async.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
/**
 * Classe que irá lidar com a negociação com a API nas rotas do usuário
 */
export class UserInteractor {
  //#region Constructors

  constructor(private readonly http: HttpAsyncService) {}

  //#endregion

  //#region Functions

  /**
   * Método para autenticar o usuário
   */
  public async userAuth(login: LoginPayload): Promise<AsyncResult<TokenProxy>> {
    return await this.http.post(environment.routes.auth, login);
  }

  /**
   * Método para buscar as informações do usuário logado
   */
  public async getMe(): Promise<AsyncResult<UserProxy>> {
    return await this.http.get<UserProxy>(environment.routes.getMe);
  }

  /**
   * Método para criar um usuário externo
   * @param payload As informações para a criação
   */
  public async createUser(
    payload: RegisterPayload
  ): Promise<AsyncResult<RegisterPayload>> {
    return await this.http.post(environment.routes.userRegister, payload);
  }

  /**
   * Método que envia um e-mail de recuperação
   * @param email O e-mail do usuário
   */
  public async forgotPassword(
    email: string
  ): Promise<AsyncResult<ForgotPasswordProxy>> {
    return await this.http.post<ForgotPasswordProxy>(
      environment.routes.forgotPassword.replace('{email}', email),
      {}
    );
  }

  /**
   * Método que recupera a senha do usuário
   * @param newPassword A nova senha do usuário
   * @param resetPasswordCode O código para recuperação da senha
   */
  public async resetPassword(
    newPassword: string,
    resetPasswordCode: string
  ): Promise<AsyncResult<void>> {
    return await this.http.post(
      environment.routes.resetPassword.replace(
        '{resetPasswordCode}',
        resetPasswordCode
      ),
      { newPassword }
    );
  }

  /**
   * Método para atualizar o usuário
   * @param userId A identificação do usuário
   * @param payload As informações para o usuário
   */
  public async updateMe(payload: UserPayload): Promise<AsyncResult<UserProxy>> {
    const url = environment.routes.updateMe.replace(
      '{userId}',
      payload.id.toString()
    );

    return await this.http.put<UserProxy>(url, {
      name: payload.name,
      email: payload.email,
      avatarUrl: payload.avatarUrl,
    });
  }

  //#endregion
}
