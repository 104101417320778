import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './guards/login.guard';

/**
 * As configurações para as rotas que NÃO precisam de autenticação
 */
export const unAuthenticatedRoute = {
  canActivate: [LoginGuard],
  data: { routeToRedirect: '/main/subjects', unprotectedRoute: true },
};

/**
 * As configurações para as rotas que PRECISAM de autenticação
 */
export const authenticatedRoute = {
  canActivate: [LoginGuard],
  data: { routeToRedirect: '/login', protectedRoute: true },
};

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'main',
    loadChildren: () =>
      import('./pages/main/main.module').then((m) => m.MainModule),
    ...authenticatedRoute,
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
    ...unAuthenticatedRoute,
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then((m) => m.RegisterModule),
    ...unAuthenticatedRoute,
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
    ...unAuthenticatedRoute,
  },
  { path: '**', redirectTo: '/main/subjects' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
