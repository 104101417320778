export const environment = {
  production: true,
  apiBaseUrl: 'https://api.novaaula.com/',
  keys: {
    token: 'USER_TOKEN',
    userInfo: 'USER_INFO',
  },
  routes: {
    auth: '/auth/local',
    userRegister: '/user',
    forgotPassword: '/user/password/forgot/email/{email}',
    resetPassword: '/user/password/reset/{resetPasswordCode}',
    getMe: '/user/me',
    updateMe: '/user/{userId}',
    getAllSubjects: '/subject?filter=isActive||$eq||true',
    getOneSubject:
      '/subject/{subjectId}?join=teacher&filter=isActive||$eq||true',
    getAllLessonsBySubjectId:
      '/subject/{subjectId}?join=lessons&join=lessons.userLessonSteps&filter=isActive||$eq||true&sort=lessons.id,ASC',
    getAllUserLessonStepsByLessonId:
      '/lesson/{classesId}?join=userLessonSteps&filter=userLessonSteps.isActive||$eq||true',
    createUserLessonStep: '/user-lesson-step',
    updateUserLessonStep: '/user-lesson-step/{userLessonStepId}',
    updateProgress: '/user-lesson-step/progress',
    getLessonBlockByLessonId:
      '/lesson-block?join=lesson&join=lesson.subject&join=lesson.subject.teacher&filter=lessonId||$eq||{lessonId}&filter=isActive||$eq||true',
    getOneLessonBlock:
      '/lesson-block/{classId}?join=lesson&join=lesson.subject&join=lesson.subject.teacher&filter=isActive||$eq||true',
    getAllTopics:
      '/topic?join=lesson&join=lesson.subject&join=lesson.subject.teacher&filter=isActive||$eq||true',
    getOneTopic:
      '/topic/{topicId}?join=lesson&join=lesson.subject&join=lesson.subject.teacher&filter=isActive||$eq||true',
    getAllQuizzesByLessonId:
      '/quiz?join=lesson&join=lesson.subject&join=lesson.subject.teacher&filter=lessonId||$eq||{lessonId}&filter=isActive||$eq||true&sort=createdAt,ASC',
    getAllUserQuizByQuizId:
      '/user-quiz?&filter=quizId||$eq||{quizId}&filter=isActive||$eq||true',
    saveAnswerQuiz: '/user-quiz',
    updateSecondAnswerQuiz: '/user-quiz/{userQuizId}',
    getAllLessonNotesByUserId:
      '/lesson-note?join=lessonBlock&join=lessonBlock.lesson&join=lessonBlock.lesson.subject&join=lessonBlock.lesson.subject.teacher&filter=userId||$eq||{userId}&filter=isActive||$eq||true&sort=createdAt,DESC',
    getAllLessonNotesByLessonBlockIdAndUserId:
      '/lesson-note?join=lessonBlock&join=lessonBlock.lesson&join=lessonBlock.lesson.subject&join=lessonBlock.lesson.subject.teacher&filter=lessonBlockId||$eq||{lessonBlockId}&filter=userId||$eq||{userId}&filter=isActive||$eq||true&sort=createdAt,DESC',
    getOneLessonNote:
      '/lesson-note/{noteId}?join=lessonBlock&join=lessonBlock.lesson&join=lessonBlock.lesson.subject&join=lessonBlock.lesson.subject.teacher&filter=isActive||$eq||true',
    createLessonNote: '/lesson-note',
    updateLessonNote: '/lesson-note/{noteId}',
    deleteLessonNote: '/lesson-note/{noteId}',
    getAllChat: '/chat/list?filter=isActive||$eq||true',
    sendMessageOnChat: '/chat',
    uploadMedia: '/media/presigned',
  },
  sentry: {
    dns: 'https://8323739040d244f2a5a31825dfcc7167@sentry.ligafacens.com/16',
    server: 'https://sentry.ligafacens.com/api',
    release: '1.0.0',
    tags: {
      framework: 'angular',
      provider: 'liga',
      type: 'app',
      name: 'liga.web.angular.nova-aula-v2',
    },
    feedback: {
      lang: 'pt-BR',
      title: 'Parece que estamos tendo alguns problemas.',
      subtitle: 'Nossa equipe foi notificada.',
      subtitle2: 'Se você quiser ajudar, conte-nos o que aconteceu abaixo.',
      labelName: 'Nome',
      labelEmail: 'E-mail',
      labelComments: 'O que aconteceu?',
      labelSubmit: 'Enviar',
      labelClose: 'Fechar',
      errorGeneric:
        'Ocorreu um erro desconhecido ao enviar o seu feedback. Por favor, tente novamente.',
      errorFormEntry:
        'Alguns campos são inválidos. Corrija os erros e tente novamente.',
      successMessage: 'Seu feedback foi enviado. Obrigado!',
    },
  },
};
