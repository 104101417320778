//#region Imports

import { Injectable } from '@angular/core';

import * as Sentry from '@sentry/browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { RegisterPayload } from 'src/app/models/payloads/register.payload';
import { UserPayload } from 'src/app/models/payloads/user.payload';
import { getErrorMessage } from 'src/app/utils/functions';
import { environment } from '../../../environments/environment';
import { UserInteractor } from '../../interactors/user/user.interactor';
import { LoginPayload } from '../../models/payloads/login.payload';
import { UserProxy } from '../../models/proxies/user.proxy';
import { HelperService } from '../helper/helper.service';
import { StorageService } from '../storage/storage.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
/**
 * O serviço que lido as informações do usuário
 */
export class UserService {
  //#region Constructors

  constructor(
    private readonly userInteractor: UserInteractor,
    private readonly storageService: StorageService,
    private readonly helper: HelperService
  ) {}

  //#endregion

  //#region Properties

  /**
   * O evento sobre as informações do usuário
   */
  private readonly user$: BehaviorSubject<UserProxy | undefined> =
    new BehaviorSubject<UserProxy | undefined>(void 0);

  //#region Functions

  /**
   * Método que retorna um Observable do usuário
   */
  public getCurrentUser$(): Observable<UserProxy> {
    return this.user$.asObservable();
  }

  /**
   * Método que atualizar as informações do usuário
   */
  public setCurrentUser(user: UserProxy): void {
    this.user$.next(user);
  }

  /**
   * Método para autenticar o usuário
   */
  public async userAuth(login: LoginPayload): Promise<boolean> {
    Sentry.addBreadcrumb({
      message: `Tentando autenticação na app.`,
      type: Sentry.Severity.Info,
    });

    Sentry.setUser({
      username: login.username,
      email: login.username,
    });

    const { error, success } = await this.userInteractor.userAuth(login);

    if (error) return false;

    await this.storageService.setItem(environment.keys.token, success.token);
    return true;
  }

  /**
   * Método para buscar as informações do usuário
   */
  public async getMe(): Promise<UserProxy> {
    const { error, success } = await this.userInteractor.getMe();

    if (error) {
      await this.helper.showAlert(
        'As informações do usuário podem estar desatualizadas, tente novamente mais tarde.',
        'Atenção'
      );
    }

    await this.storageService.setItem<UserProxy>(
      environment.keys.userInfo,
      success
    );

    this.setCurrentUser(success);

    return success;
  }

  /**
   * Método que configura a autenticação
   */
  public async setupAuthentication(): Promise<void> {
    const { error, success } = await this.storageService.getItem<UserProxy>(
      environment.keys.userInfo
    );

    if (error || !success) return;

    Sentry.setUser({
      id: success.id?.toString(),
      email: success.email,
      username: success.email,
    });

    if (this.user$.getValue()?.id === success.id) return;

    this.user$.next(success);
  }

  /**
   * Método que diz se está autenticado
   */
  public isAuthenticated(): boolean {
    return !!this.user$.getValue();
  }

  /**
   * Método que realiza o logout de um usuário
   */
  public async logout(): Promise<void> {
    this.user$.next(void 0);

    await this.storageService.remove(environment.keys.token);
  }

  /**
   * Método para criar um usuário externo
   */
  public async createUser(payload: RegisterPayload): Promise<boolean> {
    const { error, success } = await this.userInteractor.createUser(payload);

    if (error) {
      await this.helper.showAlert('Erro na criação de usuário.', 'Erro');
      return false;
    }

    await this.userAuth({
      username: payload.email,
      password: payload.password,
    });

    return true;
  }

  /**
   * Método que envia um e-mail de recuperação
   */
  public async forgotPassword(email: string): Promise<boolean> {
    const { error, success } = await this.userInteractor.forgotPassword(email);

    if (error) {
      await this.helper.showAlert('Erro na recuperação de senha.', 'Erro');
      return false;
    }

    await this.helper.showAlert(
      'Seu código foi encaminhado para o seu email.',
      'Sucesso'
    );
    return true;
  }

  /**
   * Método que realiza a recuperação da senha
   */
  public async resetPassword(
    newPassword: string,
    resetPasswordCode: string
  ): Promise<boolean> {
    const { error } = await this.userInteractor.resetPassword(
      newPassword,
      resetPasswordCode
    );

    if (error) {
      await this.helper.showAlert('Erro na recuperação de senha.', 'Erro');
      return false;
    }

    await this.helper.showAlert('Senha alterada com sucesso.', 'Sucesso');
    return true;
  }

  /**
   * Método para atualizar o usuário
   * @param userId A identificação do usuário
   * @param payload As informações para o usuário
   */
  public async updateMe(
    payload: UserPayload
  ): Promise<[UserProxy | null, string?]> {
    const { error, success } = await this.userInteractor.updateMe(payload);

    if (error) return [null, getErrorMessage(error)];

    return [success];
  }

  //#endregion
}
