//#region Imports

import { Component } from '@angular/core';
import { NzIconService } from 'ng-zorro-antd/icon';
import { PWAService } from './services/pwa/pwa.service';
import {
  whiteLogo,
  greenLogo,
  close,
  leftArrow,
  file,
  file2,
  search,
  speechBubble,
  speechBubbleMob,
  questionMark,
  quiz,
  options,
  notes,
  forum,
  myHistory,
  files,
  progressBarGray,
  progressBarGreen,
  mobSubjects,
  mobSubjectsSelected,
  mobMedias,
  mobFiles,
  mobNotes,
  mobMediasSelected,
  mobFilesSelected,
  mobNotesSelected,
  notesSelected,
  filesSelected,
  forumSelected,
  myHistorySelected,
} from './utils/icons';

//#endregion

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private readonly pwa: PWAService,
    private readonly nzIcon: NzIconService
  ) {
    this.nzIcon.addIconLiteral('app:green-logo', greenLogo);
    this.nzIcon.addIconLiteral('app:white-logo', whiteLogo);
    this.nzIcon.addIconLiteral('app:close', close);
    this.nzIcon.addIconLiteral('app:left-arrow', leftArrow);
    this.nzIcon.addIconLiteral('app:file', file);
    this.nzIcon.addIconLiteral('app:file-2', file2);
    this.nzIcon.addIconLiteral('app:search', search);
    this.nzIcon.addIconLiteral('app:speech-bubble', speechBubble);
    this.nzIcon.addIconLiteral('app:speech-bubble-mob', speechBubbleMob);
    this.nzIcon.addIconLiteral('app:question-mark', questionMark);
    this.nzIcon.addIconLiteral('app:quiz', quiz);
    this.nzIcon.addIconLiteral('app:options', options);
    this.nzIcon.addIconLiteral('app:notes', notes);
    this.nzIcon.addIconLiteral('app:notes-selected', notesSelected);
    this.nzIcon.addIconLiteral('app:forum', forum);
    this.nzIcon.addIconLiteral('app:forum-selected', forumSelected);
    this.nzIcon.addIconLiteral('app:my-history', myHistory);
    this.nzIcon.addIconLiteral('app:my-history-selected', myHistorySelected);
    this.nzIcon.addIconLiteral('app:files', files);
    this.nzIcon.addIconLiteral('app:files-selected', filesSelected);
    this.nzIcon.addIconLiteral('app:progress-bar-gray', progressBarGray);
    this.nzIcon.addIconLiteral('app:progress-bar-green', progressBarGreen);
    this.nzIcon.addIconLiteral('app:mob-subjects', mobSubjects);
    this.nzIcon.addIconLiteral(
      'app:mob-subjects-selected',
      mobSubjectsSelected
    );
    this.nzIcon.addIconLiteral('app:mob-medias', mobMedias);
    this.nzIcon.addIconLiteral('app:mob-medias-selected', mobMediasSelected);
    this.nzIcon.addIconLiteral('app:mob-files', mobFiles);
    this.nzIcon.addIconLiteral('app:mob-files-selected', mobFilesSelected);
    this.nzIcon.addIconLiteral('app:mob-notes', mobNotes);
    this.nzIcon.addIconLiteral('app:mob-notes-selected', mobNotesSelected);

    this.pwa.initializePWA();
  }
}
