export const greenLogo = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="158.334"
    height="105.603"
    viewBox="0 0 158.334 105.603"
  >
    <g id="logo" transform="translate(-507.809 281.633)">
      <g
        id="Path_226"
        data-name="Path 226"
        transform="translate(43.485 1)"
        fill="none"
        stroke-miterlimit="10"
      >
        <path
          d="M622.658-177.029H586.671l-45.8-78.1,15.359-26.5h5.46Z"
          stroke="none"
        />
        <path
          class="svg"
          d="M 615.6962280273438 -181.0292358398438 L 559.3875122070312 -277.632568359375 L 558.5304565429688 -277.632568359375 L 545.4966430664062 -255.1459045410156 L 588.9624633789062 -181.0292358398438 L 615.6962280273438 -181.0292358398438 M 622.65771484375 -177.0292358398438 L 586.671142578125 -177.0292358398438 L 540.866455078125 -255.1340789794922 L 556.2255859375 -281.632568359375 L 561.6859130859375 -281.632568359375 L 622.65771484375 -177.0292358398438 Z"
          stroke="none"
          fill="#00b65b"
        />
      </g>
      <g
        id="Path_227"
        data-name="Path 227"
        transform="translate(0)"
        fill="none"
        stroke-miterlimit="10"
      >
        <path
          d="M507.809-177.029H543.8l61.345-104.6H568.781Z"
          stroke="none"
        />
        <path
          class="svg"
          d="M 541.5044555664062 -181.0292358398438 L 598.1578369140625 -277.632568359375 L 571.0796508789062 -277.632568359375 L 514.7706909179688 -181.0292358398438 L 541.5044555664062 -181.0292358398438 M 543.7957763671875 -177.0292358398438 L 507.8092041015625 -177.0292358398438 L 568.78125 -281.632568359375 L 605.1407470703125 -281.632568359375 L 543.7957763671875 -177.0292358398438 Z"
          stroke="none"
          fill="#00b65b"
        />
      </g>
      <path
        id="Path_228"
        data-name="Path 228"
        d="M544.691-260.856"
        transform="translate(48.516 27.33)"
        fill="none"
        stroke="#707070"
        stroke-miterlimit="10"
        stroke-width="4"
      />
      <path
        class="svg"
        id="Path_229"
        data-name="Path 229"
        d="M591.063-204.953,566.23-248.738l-6.529-11.51L544.757-233.9h0l-16.419,28.945Zm-21.918-29.23L583.089-209.6H536.312l13.819-24.363Z"
        transform="translate(27.005 28.13)"
        fill="#00b65b"
      />
      <path
        id="Path_230"
        data-name="Path 230"
        d="M549.34-259.044"
        transform="translate(54.631 29.714)"
        fill="#fff"
      />
    </g>
  </svg>
`;

export const whiteLogo = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="158.334"
    height="105.603"
    viewBox="0 0 158.334 105.603"
  >
    <g id="logo" transform="translate(-507.809 281.633)">
      <g
        id="Path_226"
        data-name="Path 226"
        transform="translate(43.485 1)"
        fill="none"
        stroke-miterlimit="10"
      >
        <path
          d="M622.658-177.029H586.671l-45.8-78.1,15.359-26.5h5.46Z"
          stroke="none"
        />
        <path
          class="svg"
          d="M 615.6962280273438 -181.0292358398438 L 559.3875122070312 -277.632568359375 L 558.5304565429688 -277.632568359375 L 545.4966430664062 -255.1459045410156 L 588.9624633789062 -181.0292358398438 L 615.6962280273438 -181.0292358398438 M 622.65771484375 -177.0292358398438 L 586.671142578125 -177.0292358398438 L 540.866455078125 -255.1340789794922 L 556.2255859375 -281.632568359375 L 561.6859130859375 -281.632568359375 L 622.65771484375 -177.0292358398438 Z"
          stroke="none"
          fill="#ffff"
        />
      </g>
      <g
        id="Path_227"
        data-name="Path 227"
        transform="translate(0)"
        fill="none"
        stroke-miterlimit="10"
      >
        <path
          d="M507.809-177.029H543.8l61.345-104.6H568.781Z"
          stroke="none"
        />
        <path
          class="svg"
          d="M 541.5044555664062 -181.0292358398438 L 598.1578369140625 -277.632568359375 L 571.0796508789062 -277.632568359375 L 514.7706909179688 -181.0292358398438 L 541.5044555664062 -181.0292358398438 M 543.7957763671875 -177.0292358398438 L 507.8092041015625 -177.0292358398438 L 568.78125 -281.632568359375 L 605.1407470703125 -281.632568359375 L 543.7957763671875 -177.0292358398438 Z"
          stroke="none"
          fill="#ffff"
        />
      </g>
      <path
        id="Path_228"
        data-name="Path 228"
        d="M544.691-260.856"
        transform="translate(48.516 27.33)"
        fill="none"
        stroke="#707070"
        stroke-miterlimit="10"
        stroke-width="4"
      />
      <path
        class="svg"
        id="Path_229"
        data-name="Path 229"
        d="M591.063-204.953,566.23-248.738l-6.529-11.51L544.757-233.9h0l-16.419,28.945Zm-21.918-29.23L583.089-209.6H536.312l13.819-24.363Z"
        transform="translate(27.005 28.13)"
        fill="#ffff"
        />
      <path
        id="Path_230"
        data-name="Path 230"
        d="M549.34-259.044"
        transform="translate(54.631 29.714)"
        fill="#fff"
      />
    </g>
  </svg>
`;

export const close = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 73.109 73.109"
  >
    <defs>
      <filter
        id="Ellipse_109"
        x="0"
        y="0"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="close" transform="translate(-886.22 -1452.761) rotate(45)">
      <g
        transform="matrix(0.71, -0.71, 0.71, 0.71, 1653.91, 400.61)"
        filter="url(#Ellipse_109)"
      >
        <circle
          id="Ellipse_109-2"
          data-name="Ellipse 109"
          cx="19.484"
          cy="19.484"
          r="19.484"
          transform="translate(36.55 6) rotate(45)"
          fill="#00b397"
        />
      </g>
      <g
        id="Group_343"
        data-name="Group 343"
        transform="translate(1703.893 392.395) rotate(45)"
      >
        <path
          id="Path_307"
          data-name="Path 307"
          d="M0,0,9.189,9.189"
          transform="translate(0 0)"
          fill="none"
          stroke="#fcfcfc"
          stroke-linecap="round"
          stroke-width="3"
        />
        <path
          id="Path_308"
          data-name="Path 308"
          d="M0,0,9.189,9.189"
          transform="translate(0 9.189) rotate(-90)"
          fill="none"
          stroke="#fcfcfc"
          stroke-linecap="round"
          stroke-width="3"
        />
      </g>
    </g>
  </svg>
`;

export const leftArrow = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="14.54"
    height="20.074"
    viewBox="0 0 14.54 20.074"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#00b397" />
        <stop offset="1" stop-color="#00b65b" />
      </linearGradient>
    </defs>
    <path
      id="coltar_verde"
      d="M193.035,55.093,184.582,48.1a3.044,3.044,0,0,0-4.984,2.345V64.431a3.044,3.044,0,0,0,4.984,2.346l8.453-6.993a3.046,3.046,0,0,0,0-4.691Z"
      transform="translate(194.138 67.475) rotate(180)"
      fill="url(#linear-gradient)"
    />
  </svg>
`;

export const file = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.501"
    height="22.501"
    viewBox="0 0 18.501 22.501"
  >
    <g id="filesx" transform="translate(-2.75 -0.75)">
      <path
        id="Caminho_71"
        data-name="Caminho 71"
        d="M13,2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V9Z"
        fill="none"
        stroke="#00b397"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.5"
      />
      <path
        id="Caminho_72"
        data-name="Caminho 72"
        d="M13,2v8.75h8.75"
        transform="translate(-1.75)"
        fill="none"
        stroke="#00b397"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.5"
      />
    </g>
  </svg>
`;

export const file2 = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.3"
    height="17.5"
    viewBox="0 0 14.3 17.5"
  >
    <g transform="translate(-3.25 -1.25)">
      <path
        d="M12,2H5.6A1.6,1.6,0,0,0,4,3.6V16.4A1.6,1.6,0,0,0,5.6,18h9.6a1.6,1.6,0,0,0,1.6-1.6V6.8Z"
        fill="none"
        stroke="#00B397"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M14,2V6.8h4.8"
        transform="translate(-2)"
        fill="none"
        stroke="#00B397"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <line
        x1="6.4"
        transform="translate(7.2 10.8)"
        fill="none"
        stroke="#00B397"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <line
        x1="6.4"
        transform="translate(7.2 14)"
        fill="none"
        stroke="#00B397"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M9.6,9H8"
        transform="translate(-0.8 -1.4)"
        fill="none"
        stroke="#00B397"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </g>
  </svg>
`;

export const search = `
  <svg
    id="search"
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
  >
    <path
      id="Caminho_67"
      data-name="Caminho 67"
      d="M20.708,18.583H19.589l-.4-.382a9.222,9.222,0,1,0-.992.992l.382.4v1.119l3.847,3.808,2.111-2.111Zm-8.5,0a6.375,6.375,0,1,1,6.375-6.375A6.366,6.366,0,0,1,12.208,18.583Z"
      transform="translate(3.229 3.242)"
      fill="#00b397"
    />
    <path
      id="Caminho_68"
      data-name="Caminho 68"
      d="M0,0H34V34H0Z"
      fill="none"
    />
  </svg>
`;

export const speechBubble = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="193"
    height="133.664"
    viewBox="0 0 193 133.664"
  >
    <defs>
      <filter
        id="a"
        x="0"
        y="0"
        width="193"
        height="133.664"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="b" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="b" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g transform="matrix(1, 0, 0, 1, 0, 0)" fill="var(--color-box)" filter="url(#a)">
      <path
        d="M-69.375,181.743h-165a5,5,0,0,0-5,5v96a5,5,0,0,0,5,5h71.736l8.886,8.885a2.654,2.654,0,0,0,3.756,0l8.886-8.885h71.736a5,5,0,0,0,5-5v-96A5,5,0,0,0-69.375,181.743Z"
        transform="translate(248.38 -175.74)"
      />
    </g>
  </svg>
`;

export const speechBubbleMob = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42.441"
    height="42.638"
    viewBox="0 0 42.441 42.638"
  >
    <path
      id="balao"
      d="M-140.792,239.743h-34.724a3.859,3.859,0,0,0-3.858,3.858v29.78a3.859,3.859,0,0,0,3.858,3.858h10.6l5.314,4.541a2.048,2.048,0,0,0,2.9,0l5.314-4.541h10.6a3.859,3.859,0,0,0,3.858-3.858V243.6A3.859,3.859,0,0,0-140.792,239.743Z"
      transform="translate(179.375 -239.743)"
      fill="var(--color-bg-ballon)"
    />
  </svg>
`;

export const questionMark = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="47"
    viewBox="0 0 20 47"
  >
    <text
      id="_"
      data-name="?"
      transform="translate(0 37)"
      fill="#00b397"
      font-size="36"
      font-family="ChronicaPro-Heavy, ChronicaPro ☞"
      font-weight="800"
    >
      <tspan x="0" y="0">?</tspan>
    </text>
  </svg>
`;

export const quiz = `
  <svg xmlns="http://www.w3.org/2000/svg" width="66.786" height="48.109" viewBox="0 0 66.786 48.109">
    <g id="Grupo_78" data-name="Grupo 78" transform="translate(-0.394 -14.266)">
      <path id="Caminho_247" data-name="Caminho 247" d="M64.846,52.644A18.793,18.793,0,0,0,44.363,25.232a24,24,0,0,0-3.2-3.97A23.886,23.886,0,0,0,4,50.794L1.91,59.113a1.685,1.685,0,0,0,2.045,2.046l8.633-2.172a23.888,23.888,0,0,0,11.687,3.047h0a23.713,23.713,0,0,0,12.6-3.593,18.8,18.8,0,0,0,21.308,1.176l6.252,1.573a1.685,1.685,0,0,0,2.044-2.045Zm-30.532,3.4a20.538,20.538,0,0,1-20.6-.3,1.7,1.7,0,0,0-1.28-.189L5.866,57.2l1.585-6.3a1.685,1.685,0,0,0-.235-1.35A20.516,20.516,0,0,1,38.778,23.645,20.805,20.805,0,0,1,41.013,26.3a20.516,20.516,0,0,1-2.235,26.362,21.284,21.284,0,0,1-1.644,1.474A20.547,20.547,0,0,1,34.314,56.043Zm27.1-3.238,1.115,4.43L58.3,56.173a1.677,1.677,0,0,0-1.351.235,15.434,15.434,0,0,1-17.222-.053c.491-.417.971-.853,1.432-1.313a23.911,23.911,0,0,0,4.9-26.7,15.549,15.549,0,0,1,2.323-.174A15.421,15.421,0,0,1,61.6,51.525,1.683,1.683,0,0,0,61.41,52.805Z" transform="translate(0)" fill="#fff"/>
      <g id="Grupo_72" data-name="Grupo 72" transform="translate(18.159 28.351)">
        <g id="Grupo_70" data-name="Grupo 70" transform="translate(0 0)">
          <path id="Caminho_248" data-name="Caminho 248" d="M32.452,50.1a1.583,1.583,0,0,1-1.191-.479,1.728,1.728,0,0,1-.45-1.243V45.383c0-.321.009-.574.029-.774a1.191,1.191,0,0,1,.35-.755,1.913,1.913,0,0,1,.731-.429,8.311,8.311,0,0,1,1.13-.285,2.929,2.929,0,0,0,1.089-.412,2.165,2.165,0,0,0,.6-.592,2.077,2.077,0,0,0,.28-.677,3.455,3.455,0,0,0,.082-.709,2.261,2.261,0,0,0-.7-1.778,2.679,2.679,0,0,0-1.89-.658,3.174,3.174,0,0,0-1.6.387,2.63,2.63,0,0,0-1.019,1.467,1.864,1.864,0,0,1-.594.93,1.489,1.489,0,0,1-.888.261A1.623,1.623,0,0,1,27.27,40.9a1.555,1.555,0,0,1-.483-1.259,3.516,3.516,0,0,1,.58-1.84,5.483,5.483,0,0,1,1.319-1.392,6.058,6.058,0,0,1,1.766-.905,6.4,6.4,0,0,1,1.973-.314,7.041,7.041,0,0,1,2.385.4A6.145,6.145,0,0,1,36.747,36.7a5.314,5.314,0,0,1,1.309,1.686,4.842,4.842,0,0,1,.486,2.157,5.321,5.321,0,0,1-1.36,3.781A6.38,6.38,0,0,1,34.094,46.1v2.271a1.725,1.725,0,0,1-.45,1.243A1.581,1.581,0,0,1,32.452,50.1Z" transform="translate(-26.785 -35.19)" fill="#fff"/>
        </g>
        <g id="Grupo_71" data-name="Grupo 71" transform="translate(3.84 17.258)">
          <path id="Caminho_249" data-name="Caminho 249" d="M34.317,64.48a1.831,1.831,0,1,1,1.29-.536A1.764,1.764,0,0,1,34.317,64.48Z" transform="translate(-32.489 -60.827)" fill="#fff"/>
        </g>
      </g>
    </g>
  </svg>
`;

export const options = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="24"
    viewBox="0 0 6 24"
  >
    <g id="dots" transform="translate(-1678 -451)" fill="var(--color-options)">
      <circle
        id="Ellipse_106"
        data-name="Ellipse 106"
        cx="3"
        cy="3"
        r="3"
        transform="translate(1678 451)"
      />
      <circle
        id="Ellipse_107"
        data-name="Ellipse 107"
        cx="3"
        cy="3"
        r="3"
        transform="translate(1678 460)"
      />
      <circle
        id="Ellipse_108"
        data-name="Ellipse 108"
        cx="3"
        cy="3"
        r="3"
        transform="translate(1678 469)"
      />
    </g>
  </svg>
`;

export const notes = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="66.702"
    height="73.874"
    viewBox="0 0 66.702 73.874"
  >
    <g id="notes_white" transform="translate(-138.404 -121.82)">
      <path
        id="Path_319"
        data-name="Path 319"
        d="M139.406,158.647q0-11.968,0-23.935c.01-7.076,4.809-11.888,11.843-11.891q16.517-.006,33.033,0c7.333,0,11.995,4.281,12.269,11.615.2,5.451.091,10.917.022,16.374-.033,2.637-2.046,4.125-4.125,3.2a3.112,3.112,0,0,1-1.818-3.186q.023-7.908,0-15.817c-.005-4.142-2.14-6.366-6.26-6.377q-16.447-.045-32.893,0c-3.924.011-6.089,2.185-6.093,6.067q-.024,24.075,0,48.15c0,3.817,2.15,5.972,5.981,6.039,2.8.049,5.6,0,8.4.017,1.962.011,3.267,1.155,3.3,2.85.03,1.735-1.313,2.928-3.351,2.936q-4.549.019-9.1,0a11.246,11.246,0,0,1-11.191-11.123c-.061-8.3-.015-16.61-.015-24.914Z"
        transform="translate(0 0)"
        fill="#a8a8a8"
        stroke-width="1"
      />
      <path
        id="Path_320"
        data-name="Path 320"
        d="M273.9,258.74a8.225,8.225,0,0,1,7.618,4.98,7.69,7.69,0,0,1-1.247,9c-5.125,5.412-10.5,10.591-15.771,15.861a3.484,3.484,0,0,1-1.578.862c-3.5.965-6.994,1.963-10.506,2.889-2.98.786-4.81-1.061-3.965-4.022.97-3.4,2.037-6.779,3.058-10.168a4.978,4.978,0,0,1,1.345-2.091q7.281-7.252,14.548-14.518A8.634,8.634,0,0,1,273.9,258.74Zm-18.351,26.59c1.033-.275,1.785-.546,2.56-.667a8.083,8.083,0,0,0,4.834-2.789c1.728-1.892,3.608-3.645,5.423-5.458,2.735-2.732,2.708-2.706-.011-5.442-.763-.768-1.241-.829-2.026-.023-2.374,2.439-4.709,4.938-7.285,7.152C256.83,280.009,256.36,282.551,255.552,285.33Zm20.788-18.047a2.541,2.541,0,0,0-3.8-2.315c-.74.417-1.043.9-.288,1.617.776.732,1.533,1.487,2.263,2.265.647.689,1.1.474,1.513-.23A2.624,2.624,0,0,0,276.341,267.283Z"
        transform="translate(-78.377 -97.865)"
        fill="#a8a8a8"
        stroke-width="1"
      />
      <path
        id="Path_321"
        data-name="Path 321"
        d="M196.754,188.612q-6.926,0-13.852,0c-2.091,0-3.384-1.117-3.392-2.881s1.289-2.9,3.367-2.906q13.992-.011,27.984,0c2.089,0,3.336,1.139,3.305,2.949-.029,1.742-1.26,2.829-3.28,2.835C206.175,188.621,201.464,188.611,196.754,188.612Z"
        transform="translate(-28.884 -43.2)"
        fill="#a8a8a8"
        stroke-width="1"
      />
      <path
        id="Path_322"
        data-name="Path 322"
        d="M196.916,222.819c4.617,0,9.235-.007,13.852,0,2.126,0,3.378,1.075,3.4,2.86.018,1.8-1.233,2.924-3.329,2.926q-13.992.013-27.984,0c-2.065,0-3.368-1.165-3.343-2.929s1.307-2.853,3.416-2.857Q189.92,222.809,196.916,222.819Z"
        transform="translate(-28.883 -72.001)"
        fill="#a8a8a8"
        stroke-width="1"
      />
      <path
        id="Path_323"
        data-name="Path 323"
        d="M191.315,262.814c2.795,0,5.59-.016,8.384.005a2.9,2.9,0,1,1,.039,5.779q-8.524.027-17.048,0a2.9,2.9,0,1,1-.039-5.779C185.539,262.8,188.427,262.814,191.315,262.814Z"
        transform="translate(-28.882 -100.796)"
        fill="#a8a8a8"
        stroke-width="1"
      />
    </g>
  </svg>
`;

export const notesSelected = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="66.702"
    height="73.874"
    viewBox="0 0 66.702 73.874"
  >
    <g id="notes_white" transform="translate(-138.404 -121.82)">
      <path
        id="Path_319"
        data-name="Path 319"
        d="M139.406,158.647q0-11.968,0-23.935c.01-7.076,4.809-11.888,11.843-11.891q16.517-.006,33.033,0c7.333,0,11.995,4.281,12.269,11.615.2,5.451.091,10.917.022,16.374-.033,2.637-2.046,4.125-4.125,3.2a3.112,3.112,0,0,1-1.818-3.186q.023-7.908,0-15.817c-.005-4.142-2.14-6.366-6.26-6.377q-16.447-.045-32.893,0c-3.924.011-6.089,2.185-6.093,6.067q-.024,24.075,0,48.15c0,3.817,2.15,5.972,5.981,6.039,2.8.049,5.6,0,8.4.017,1.962.011,3.267,1.155,3.3,2.85.03,1.735-1.313,2.928-3.351,2.936q-4.549.019-9.1,0a11.246,11.246,0,0,1-11.191-11.123c-.061-8.3-.015-16.61-.015-24.914Z"
        transform="translate(0 0)"
        fill="#00b397"
        stroke-width="1"
      />
      <path
        id="Path_320"
        data-name="Path 320"
        d="M273.9,258.74a8.225,8.225,0,0,1,7.618,4.98,7.69,7.69,0,0,1-1.247,9c-5.125,5.412-10.5,10.591-15.771,15.861a3.484,3.484,0,0,1-1.578.862c-3.5.965-6.994,1.963-10.506,2.889-2.98.786-4.81-1.061-3.965-4.022.97-3.4,2.037-6.779,3.058-10.168a4.978,4.978,0,0,1,1.345-2.091q7.281-7.252,14.548-14.518A8.634,8.634,0,0,1,273.9,258.74Zm-18.351,26.59c1.033-.275,1.785-.546,2.56-.667a8.083,8.083,0,0,0,4.834-2.789c1.728-1.892,3.608-3.645,5.423-5.458,2.735-2.732,2.708-2.706-.011-5.442-.763-.768-1.241-.829-2.026-.023-2.374,2.439-4.709,4.938-7.285,7.152C256.83,280.009,256.36,282.551,255.552,285.33Zm20.788-18.047a2.541,2.541,0,0,0-3.8-2.315c-.74.417-1.043.9-.288,1.617.776.732,1.533,1.487,2.263,2.265.647.689,1.1.474,1.513-.23A2.624,2.624,0,0,0,276.341,267.283Z"
        transform="translate(-78.377 -97.865)"
        fill="#00b397"
        stroke-width="1"
      />
      <path
        id="Path_321"
        data-name="Path 321"
        d="M196.754,188.612q-6.926,0-13.852,0c-2.091,0-3.384-1.117-3.392-2.881s1.289-2.9,3.367-2.906q13.992-.011,27.984,0c2.089,0,3.336,1.139,3.305,2.949-.029,1.742-1.26,2.829-3.28,2.835C206.175,188.621,201.464,188.611,196.754,188.612Z"
        transform="translate(-28.884 -43.2)"
        fill="#00b397"
        stroke-width="1"
      />
      <path
        id="Path_322"
        data-name="Path 322"
        d="M196.916,222.819c4.617,0,9.235-.007,13.852,0,2.126,0,3.378,1.075,3.4,2.86.018,1.8-1.233,2.924-3.329,2.926q-13.992.013-27.984,0c-2.065,0-3.368-1.165-3.343-2.929s1.307-2.853,3.416-2.857Q189.92,222.809,196.916,222.819Z"
        transform="translate(-28.883 -72.001)"
        fill="#00b397"
        stroke-width="1"
      />
      <path
        id="Path_323"
        data-name="Path 323"
        d="M191.315,262.814c2.795,0,5.59-.016,8.384.005a2.9,2.9,0,1,1,.039,5.779q-8.524.027-17.048,0a2.9,2.9,0,1,1-.039-5.779C185.539,262.8,188.427,262.814,191.315,262.814Z"
        transform="translate(-28.882 -100.796)"
        fill="#00b397"
        stroke-width="1"
      />
    </g>
  </svg>
`;

export const forum = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="86.242"
    height="71.025"
    viewBox="0 0 86.242 71.025"
  >
    <g id="forum_white" transform="translate(-131.985 -155.169)">
      <path
        id="Path_324"
        data-name="Path 324"
        d="M153.448,219.085H135.823c-.456,0-.913.006-1.368-.015-.915-.042-1.729-.294-1.933-1.327-.187-.945.4-1.5,1.165-1.872,1.177-.578,2.369-1.125,3.55-1.694,3.031-1.46,6.045-2.955,9.1-4.37,1.1-.51,1.349-.917.471-1.963a31.381,31.381,0,0,1-7.529-21.1,31.652,31.652,0,0,1,63.116-2.8,40.4,40.4,0,0,1,.087,5.64,1.776,1.776,0,0,0,1.153,1.847,17.681,17.681,0,0,1,10.287,14.176,17.255,17.255,0,0,1-2.787,11.9c-1.192,1.859-1.2,1.853.849,2.854q2.229,1.089,4.453,2.188c.81.4,1.514.918,1.234,1.967-.251.938-1.023,1.126-1.884,1.125-7.13-.009-14.268.205-21.386-.091a16.149,16.149,0,0,1-12.389-6.72,2.2,2.2,0,0,0-2.843-.926,29.08,29.08,0,0,1-8.607,1.182C164.855,219.094,159.151,219.085,153.448,219.085Zm-12.44-3.263c11.12,0,21.718.008,32.316-.015a16.986,16.986,0,0,0,2.537-.334c15.108-2.353,26.025-17.793,23.213-32.826-2.982-15.942-17.151-26.031-33.148-23.367a28.321,28.321,0,0,0-23.584,28.574,27.8,27.8,0,0,0,8.577,19.9c1.586,1.579,1.421,2.52-.562,3.5C147.4,212.722,144.432,214.152,141.007,215.822Zm67.928,6.6c-.183-.11-.361-.229-.551-.328-3.38-1.762-3.4-1.84-1.027-4.681a14.942,14.942,0,0,0-3.881-22.593c-1.048-.594-1.622-.722-2.025.731a30.918,30.918,0,0,1-16.177,19.961c-1.337.692-1.151,1.245-.325,2.118a14.529,14.529,0,0,0,8.685,4.715C198.683,223.049,203.759,222.459,208.935,222.422Z"
        transform="translate(0 0)"
        fill="#a8a8a8"
        stroke="#a8a8a8"
        stroke-width="1"
      />
      <path
        id="Path_325"
        data-name="Path 325"
        d="M210.405,230.689q-7.515,0-15.03-.006a7.113,7.113,0,0,1-1.692-.143,1.415,1.415,0,0,1-1.164-1.484,1.437,1.437,0,0,1,1.213-1.45,6.54,6.54,0,0,1,1.524-.126q15.115-.01,30.23,0a6.908,6.908,0,0,1,1.525.118,1.509,1.509,0,0,1-.05,2.979,8.4,8.4,0,0,1-1.7.109Q217.835,230.7,210.405,230.689Z"
        transform="translate(-39.486 -50.198)"
        fill="#a8a8a8"
        stroke="#a8a8a8"
        stroke-width="1"
      />
      <path
        id="Path_326"
        data-name="Path 326"
        d="M210.268,246.683q-7.43,0-14.859-.005a7.513,7.513,0,0,1-1.694-.133,1.5,1.5,0,0,1-.017-2.932,8.451,8.451,0,0,1,1.864-.139q14.859-.011,29.718,0c1.273,0,2.971-.264,2.917,1.673-.049,1.786-1.684,1.519-2.9,1.524C220.288,246.7,215.277,246.683,210.268,246.683Z"
        transform="translate(-39.485 -57.715)"
        fill="#a8a8a8"
        stroke="#a8a8a8"
        stroke-width="1"
      />
      <path
        id="Path_327"
        data-name="Path 327"
        d="M204.251,262.692c-3.3,0-6.6,0-9.906,0-1.152,0-2.36-.2-2.34-1.623s1.241-1.579,2.386-1.579q9.992-.005,19.984,0a6.68,6.68,0,0,1,.682.027c.939.1,1.727.494,1.757,1.515.034,1.128-.8,1.607-1.8,1.629-2.447.052-4.9.033-7.344.04C206.529,262.7,205.39,262.694,204.251,262.692Z"
        transform="translate(-39.148 -65.253)"
        fill="#a8a8a8"
        stroke="#a8a8a8"
        stroke-width="1"
      />
    </g>
  </svg>
`;

export const forumSelected = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="86.242"
    height="71.025"
    viewBox="0 0 86.242 71.025"
  >
    <g id="forum_white" transform="translate(-131.985 -155.169)">
      <path
        id="Path_324"
        data-name="Path 324"
        d="M153.448,219.085H135.823c-.456,0-.913.006-1.368-.015-.915-.042-1.729-.294-1.933-1.327-.187-.945.4-1.5,1.165-1.872,1.177-.578,2.369-1.125,3.55-1.694,3.031-1.46,6.045-2.955,9.1-4.37,1.1-.51,1.349-.917.471-1.963a31.381,31.381,0,0,1-7.529-21.1,31.652,31.652,0,0,1,63.116-2.8,40.4,40.4,0,0,1,.087,5.64,1.776,1.776,0,0,0,1.153,1.847,17.681,17.681,0,0,1,10.287,14.176,17.255,17.255,0,0,1-2.787,11.9c-1.192,1.859-1.2,1.853.849,2.854q2.229,1.089,4.453,2.188c.81.4,1.514.918,1.234,1.967-.251.938-1.023,1.126-1.884,1.125-7.13-.009-14.268.205-21.386-.091a16.149,16.149,0,0,1-12.389-6.72,2.2,2.2,0,0,0-2.843-.926,29.08,29.08,0,0,1-8.607,1.182C164.855,219.094,159.151,219.085,153.448,219.085Zm-12.44-3.263c11.12,0,21.718.008,32.316-.015a16.986,16.986,0,0,0,2.537-.334c15.108-2.353,26.025-17.793,23.213-32.826-2.982-15.942-17.151-26.031-33.148-23.367a28.321,28.321,0,0,0-23.584,28.574,27.8,27.8,0,0,0,8.577,19.9c1.586,1.579,1.421,2.52-.562,3.5C147.4,212.722,144.432,214.152,141.007,215.822Zm67.928,6.6c-.183-.11-.361-.229-.551-.328-3.38-1.762-3.4-1.84-1.027-4.681a14.942,14.942,0,0,0-3.881-22.593c-1.048-.594-1.622-.722-2.025.731a30.918,30.918,0,0,1-16.177,19.961c-1.337.692-1.151,1.245-.325,2.118a14.529,14.529,0,0,0,8.685,4.715C198.683,223.049,203.759,222.459,208.935,222.422Z"
        transform="translate(0 0)"
        fill="#00b397"
        stroke="#00b397"
        stroke-width="1"
      />
      <path
        id="Path_325"
        data-name="Path 325"
        d="M210.405,230.689q-7.515,0-15.03-.006a7.113,7.113,0,0,1-1.692-.143,1.415,1.415,0,0,1-1.164-1.484,1.437,1.437,0,0,1,1.213-1.45,6.54,6.54,0,0,1,1.524-.126q15.115-.01,30.23,0a6.908,6.908,0,0,1,1.525.118,1.509,1.509,0,0,1-.05,2.979,8.4,8.4,0,0,1-1.7.109Q217.835,230.7,210.405,230.689Z"
        transform="translate(-39.486 -50.198)"
        fill="#00b397"
        stroke="#00b397"
        stroke-width="1"
      />
      <path
        id="Path_326"
        data-name="Path 326"
        d="M210.268,246.683q-7.43,0-14.859-.005a7.513,7.513,0,0,1-1.694-.133,1.5,1.5,0,0,1-.017-2.932,8.451,8.451,0,0,1,1.864-.139q14.859-.011,29.718,0c1.273,0,2.971-.264,2.917,1.673-.049,1.786-1.684,1.519-2.9,1.524C220.288,246.7,215.277,246.683,210.268,246.683Z"
        transform="translate(-39.485 -57.715)"
        fill="#00b397"
        stroke="#00b397"
        stroke-width="1"
      />
      <path
        id="Path_327"
        data-name="Path 327"
        d="M204.251,262.692c-3.3,0-6.6,0-9.906,0-1.152,0-2.36-.2-2.34-1.623s1.241-1.579,2.386-1.579q9.992-.005,19.984,0a6.68,6.68,0,0,1,.682.027c.939.1,1.727.494,1.757,1.515.034,1.128-.8,1.607-1.8,1.629-2.447.052-4.9.033-7.344.04C206.529,262.7,205.39,262.694,204.251,262.692Z"
        transform="translate(-39.148 -65.253)"
        fill="#00b397"
        stroke="#00b397"
        stroke-width="1"
      />
    </g>
  </svg>
`;

export const myHistory = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="63.864"
    height="73.704"
    viewBox="0 0 63.864 73.704"
  >
    <g id="history_white" transform="translate(-162.488 -121.321)">
      <path
        id="Path_328"
        data-name="Path 328"
        d="M162.494,161.461q0-13.635,0-27.27c0-4.235,2-6.275,6.241-6.224a2.666,2.666,0,0,0,2.53-1.384,11.319,11.319,0,0,1,9.8-5.261q6.6.008,13.2,0a11.3,11.3,0,0,1,10.018,5.387,2.413,2.413,0,0,0,2.471,1.241,5.606,5.606,0,0,1,4.512,1.416,4.846,4.846,0,0,1,1.615,3.555q.031,4.091,0,8.181c-.011,1.347-.74,2.234-1.777,2.142a1.921,1.921,0,0,1-1.819-2.172c-.028-2.3-.009-4.593-.02-6.889a4.983,4.983,0,0,0-.1-1.424c-.305-.985-1.113-1.218-2.053-1.18-1.077.044-.826.844-.838,1.473-.021,1.052.015,2.105-.018,3.157a1.9,1.9,0,0,1-1.819,2.025,15.223,15.223,0,0,1-1.858.115q-14.855.01-29.71,0c-3.271,0-3.732-.468-3.728-3.732,0-.478.042-.957.029-1.434-.016-.552.558-1.347-.4-1.558a1.949,1.949,0,0,0-2.357.9,3.737,3.737,0,0,0-.309,1.82q0,27.2,0,54.4c0,2.227.448,2.651,2.714,2.651q18.945,0,37.891,0c2.24,0,2.546-.318,2.551-2.592.006-2.2-.016-4.4.01-6.6.018-1.529.742-2.409,1.885-2.389,1.125.02,1.716.8,1.724,2.329q.02,3.66,0,7.32c-.013,3.48-2.054,5.552-5.506,5.554q-19.591.012-39.182,0c-3.58,0-5.7-2.092-5.709-5.715-.028-7.176-.009-14.352-.01-21.529Zm25.337-26.882c4.446,0,8.892-.025,13.338.018,1.091.01,1.526-.3,1.506-1.465a8.274,8.274,0,0,0-7.992-8.167q-6.955-.084-13.911,0a8.026,8.026,0,0,0-7.832,7.785c-.049,1.746.024,1.827,1.84,1.829Q181.306,134.585,187.831,134.579Z"
        fill="#a8a8a8"
      />
      <path
        id="Path_329"
        data-name="Path 329"
        d="M279.092,223.569a15.193,15.193,0,1,1,15.269,15.147A15.213,15.213,0,0,1,279.092,223.569Zm3.687-.016a11.494,11.494,0,1,0,22.988-.021,11.494,11.494,0,1,0-22.988.021Z"
        transform="translate(-83.125 -62.07)"
        fill="#a8a8a8"
      />
      <path
        id="Path_330"
        data-name="Path 330"
        d="M212.6,200.33c-3.967,0-7.935.008-11.9,0-1.984-.005-3.038-.672-3.041-1.878s1.063-1.886,3.033-1.888q12.117-.011,24.234,0c2.08,0,2.985.594,2.988,1.878s-.893,1.877-2.979,1.885C220.824,200.34,216.713,200.33,212.6,200.33Z"
        transform="translate(-25.073 -53.634)"
        fill="#a8a8a8"
      />
      <path
        id="Path_331"
        data-name="Path 331"
        d="M208.61,229.4c-2.625,0-5.251.011-7.876-.009a6.868,6.868,0,0,1-1.692-.211,1.718,1.718,0,0,1,.012-3.341,6.816,6.816,0,0,1,1.695-.2q7.877-.022,15.753-.006c2.174,0,3.119.593,3.126,1.9.006,1.282-.954,1.863-3.14,1.87C213.861,229.41,211.236,229.4,208.61,229.4Z"
        transform="translate(-25.123 -74.359)"
        fill="#a8a8a8"
      />
      <path
        id="Path_332"
        data-name="Path 332"
        d="M208.723,284.264c2.863,0,5.726-.014,8.589.005,1.517.01,2.282.634,2.276,1.772-.006,1.1-.823,1.821-2.287,1.828q-8.66.041-17.321,0c-1.417-.006-2.293-.773-2.3-1.842s.844-1.75,2.314-1.759C202.9,284.252,205.813,284.264,208.723,284.264Z"
        transform="translate(-25.086 -116.156)"
        fill="#a8a8a8"
      />
      <path
        id="Path_333"
        data-name="Path 333"
        d="M207.814,258.473c-2.337,0-4.675.008-7.012-.007a7.231,7.231,0,0,1-1.559-.142,1.7,1.7,0,0,1-1.5-1.813,1.68,1.68,0,0,1,1.526-1.642,10.45,10.45,0,0,1,1.7-.16q6.941-.019,13.881-.007c.239,0,.478,0,.715.016,1.641.117,2.4.718,2.382,1.889s-.821,1.822-2.41,1.853c-1.907.037-3.816.012-5.724.013Z"
        transform="translate(-25.131 -95.084)"
        fill="#a8a8a8"
      />
      <path
        id="Path_334"
        data-name="Path 334"
        d="M252.87,313.333h6.314c1.148,0,1.975.424,2.074,1.683.087,1.105-.688,1.9-2.026,1.91q-6.385.061-12.771,0c-1.328-.013-2.1-.825-2-1.931.113-1.26.952-1.663,2.1-1.662Q249.713,313.336,252.87,313.333Z"
        transform="translate(-58.431 -136.882)"
        fill="#a8a8a8"
      />
      <path
        id="Path_335"
        data-name="Path 335"
        d="M202.886,313.339c1.1,0,2.192-.034,3.285.012a1.76,1.76,0,0,1,.157,3.515c-2.28.1-4.57.1-6.85.009a1.767,1.767,0,1,1,.123-3.532C200.7,313.318,201.791,313.338,202.886,313.339Z"
        transform="translate(-25.145 -136.879)"
        fill="#a8a8a8"
      />
      <path
        id="Path_336"
        data-name="Path 336"
        d="M246.084,148.12a1.658,1.658,0,0,1-1.628-1.66,1.72,1.72,0,0,1,1.692-1.877,1.75,1.75,0,0,1,1.8,1.666A1.815,1.815,0,0,1,246.084,148.12Z"
        transform="translate(-58.43 -16.582)"
        fill="#a8a8a8"
      />
      <path
        id="Path_337"
        data-name="Path 337"
        d="M321.909,239.611a7.5,7.5,0,0,1-.449,1.067c-1.511,2.549-3.016,5.1-4.575,7.62-.946,1.529-2.1,1.646-3.411.38-1.478-1.427-2.917-2.9-4.363-4.356-.844-.853-1.119-1.8-.248-2.788.706-.8,1.793-.7,2.782.217.77.715,1.527,1.45,2.23,2.23.626.695,1.024.651,1.488-.172.892-1.581,1.853-3.123,2.788-4.68a3.459,3.459,0,0,1,1.009-1.194,1.619,1.619,0,0,1,1.763-.092A1.874,1.874,0,0,1,321.909,239.611Z"
        transform="translate(-103.967 -82.912)"
        fill="#a8a8a8"
      />
    </g>
  </svg>
`;

export const myHistorySelected = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="63.864"
    height="73.704"
    viewBox="0 0 63.864 73.704"
  >
    <g id="history_white" transform="translate(-162.488 -121.321)">
      <path
        id="Path_328"
        data-name="Path 328"
        d="M162.494,161.461q0-13.635,0-27.27c0-4.235,2-6.275,6.241-6.224a2.666,2.666,0,0,0,2.53-1.384,11.319,11.319,0,0,1,9.8-5.261q6.6.008,13.2,0a11.3,11.3,0,0,1,10.018,5.387,2.413,2.413,0,0,0,2.471,1.241,5.606,5.606,0,0,1,4.512,1.416,4.846,4.846,0,0,1,1.615,3.555q.031,4.091,0,8.181c-.011,1.347-.74,2.234-1.777,2.142a1.921,1.921,0,0,1-1.819-2.172c-.028-2.3-.009-4.593-.02-6.889a4.983,4.983,0,0,0-.1-1.424c-.305-.985-1.113-1.218-2.053-1.18-1.077.044-.826.844-.838,1.473-.021,1.052.015,2.105-.018,3.157a1.9,1.9,0,0,1-1.819,2.025,15.223,15.223,0,0,1-1.858.115q-14.855.01-29.71,0c-3.271,0-3.732-.468-3.728-3.732,0-.478.042-.957.029-1.434-.016-.552.558-1.347-.4-1.558a1.949,1.949,0,0,0-2.357.9,3.737,3.737,0,0,0-.309,1.82q0,27.2,0,54.4c0,2.227.448,2.651,2.714,2.651q18.945,0,37.891,0c2.24,0,2.546-.318,2.551-2.592.006-2.2-.016-4.4.01-6.6.018-1.529.742-2.409,1.885-2.389,1.125.02,1.716.8,1.724,2.329q.02,3.66,0,7.32c-.013,3.48-2.054,5.552-5.506,5.554q-19.591.012-39.182,0c-3.58,0-5.7-2.092-5.709-5.715-.028-7.176-.009-14.352-.01-21.529Zm25.337-26.882c4.446,0,8.892-.025,13.338.018,1.091.01,1.526-.3,1.506-1.465a8.274,8.274,0,0,0-7.992-8.167q-6.955-.084-13.911,0a8.026,8.026,0,0,0-7.832,7.785c-.049,1.746.024,1.827,1.84,1.829Q181.306,134.585,187.831,134.579Z"
        fill="#00b397"
      />
      <path
        id="Path_329"
        data-name="Path 329"
        d="M279.092,223.569a15.193,15.193,0,1,1,15.269,15.147A15.213,15.213,0,0,1,279.092,223.569Zm3.687-.016a11.494,11.494,0,1,0,22.988-.021,11.494,11.494,0,1,0-22.988.021Z"
        transform="translate(-83.125 -62.07)"
        fill="#00b397"
      />
      <path
        id="Path_330"
        data-name="Path 330"
        d="M212.6,200.33c-3.967,0-7.935.008-11.9,0-1.984-.005-3.038-.672-3.041-1.878s1.063-1.886,3.033-1.888q12.117-.011,24.234,0c2.08,0,2.985.594,2.988,1.878s-.893,1.877-2.979,1.885C220.824,200.34,216.713,200.33,212.6,200.33Z"
        transform="translate(-25.073 -53.634)"
        fill="#00b397"
      />
      <path
        id="Path_331"
        data-name="Path 331"
        d="M208.61,229.4c-2.625,0-5.251.011-7.876-.009a6.868,6.868,0,0,1-1.692-.211,1.718,1.718,0,0,1,.012-3.341,6.816,6.816,0,0,1,1.695-.2q7.877-.022,15.753-.006c2.174,0,3.119.593,3.126,1.9.006,1.282-.954,1.863-3.14,1.87C213.861,229.41,211.236,229.4,208.61,229.4Z"
        transform="translate(-25.123 -74.359)"
        fill="#00b397"
      />
      <path
        id="Path_332"
        data-name="Path 332"
        d="M208.723,284.264c2.863,0,5.726-.014,8.589.005,1.517.01,2.282.634,2.276,1.772-.006,1.1-.823,1.821-2.287,1.828q-8.66.041-17.321,0c-1.417-.006-2.293-.773-2.3-1.842s.844-1.75,2.314-1.759C202.9,284.252,205.813,284.264,208.723,284.264Z"
        transform="translate(-25.086 -116.156)"
        fill="#00b397"
      />
      <path
        id="Path_333"
        data-name="Path 333"
        d="M207.814,258.473c-2.337,0-4.675.008-7.012-.007a7.231,7.231,0,0,1-1.559-.142,1.7,1.7,0,0,1-1.5-1.813,1.68,1.68,0,0,1,1.526-1.642,10.45,10.45,0,0,1,1.7-.16q6.941-.019,13.881-.007c.239,0,.478,0,.715.016,1.641.117,2.4.718,2.382,1.889s-.821,1.822-2.41,1.853c-1.907.037-3.816.012-5.724.013Z"
        transform="translate(-25.131 -95.084)"
        fill="#00b397"
      />
      <path
        id="Path_334"
        data-name="Path 334"
        d="M252.87,313.333h6.314c1.148,0,1.975.424,2.074,1.683.087,1.105-.688,1.9-2.026,1.91q-6.385.061-12.771,0c-1.328-.013-2.1-.825-2-1.931.113-1.26.952-1.663,2.1-1.662Q249.713,313.336,252.87,313.333Z"
        transform="translate(-58.431 -136.882)"
        fill="#00b397"
      />
      <path
        id="Path_335"
        data-name="Path 335"
        d="M202.886,313.339c1.1,0,2.192-.034,3.285.012a1.76,1.76,0,0,1,.157,3.515c-2.28.1-4.57.1-6.85.009a1.767,1.767,0,1,1,.123-3.532C200.7,313.318,201.791,313.338,202.886,313.339Z"
        transform="translate(-25.145 -136.879)"
        fill="#00b397"
      />
      <path
        id="Path_336"
        data-name="Path 336"
        d="M246.084,148.12a1.658,1.658,0,0,1-1.628-1.66,1.72,1.72,0,0,1,1.692-1.877,1.75,1.75,0,0,1,1.8,1.666A1.815,1.815,0,0,1,246.084,148.12Z"
        transform="translate(-58.43 -16.582)"
        fill="#00b397"
      />
      <path
        id="Path_337"
        data-name="Path 337"
        d="M321.909,239.611a7.5,7.5,0,0,1-.449,1.067c-1.511,2.549-3.016,5.1-4.575,7.62-.946,1.529-2.1,1.646-3.411.38-1.478-1.427-2.917-2.9-4.363-4.356-.844-.853-1.119-1.8-.248-2.788.706-.8,1.793-.7,2.782.217.77.715,1.527,1.45,2.23,2.23.626.695,1.024.651,1.488-.172.892-1.581,1.853-3.123,2.788-4.68a3.459,3.459,0,0,1,1.009-1.194,1.619,1.619,0,0,1,1.763-.092A1.874,1.874,0,0,1,321.909,239.611Z"
        transform="translate(-103.967 -82.912)"
        fill="#00b397"
      />
    </g>
  </svg>
`;

export const files = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="61.175"
    height="50.803"
    viewBox="0 0 61.175 50.803"
  >
  <path
    id="files_white"
    d="M161.48,211.162q-12.6,0-25.195,0c-2.373,0-2.9-.527-2.9-2.881q0-22.089,0-44.179c0-2.152.561-2.733,2.679-2.736q7.148-.011,14.3,0c2.058,0,2.675.579,2.623,2.648-.027,1.056.347,1.316,1.353,1.313,9.3-.032,18.593-.02,27.89-.018,2.265,0,2.8.545,2.819,2.85.008,1.133.064,2.27-.015,3.4-.082,1.152.281,1.637,1.5,1.56,1.478-.094,2.967-.031,4.451-.019,2,.017,2.74.75,2.546,2.729-.376,3.843-.829,7.678-1.255,11.516q-.879,7.907-1.766,15.814c-.217,1.938-.418,3.878-.676,5.811-.228,1.711-.84,2.192-2.691,2.193Q174.312,211.167,161.48,211.162Zm.387-3.844c7.654,0,15.308-.014,22.962.016,1.051,0,1.506-.257,1.6-1.415.241-3.069.642-6.126.981-9.188q1.016-9.185,2.037-18.369c.173-1.544.217-1.421-1.376-1.422q-21.2-.008-42.409,0c-1.892,0-1.881,0-2.253,1.819q-2.784,13.584-5.579,27.165c-.286,1.393-.294,1.4,1.2,1.4Q150.445,207.32,161.867,207.318Zm-24.8-17.007.1.071q1.5-7.27,3.005-14.54c.486-2.377.917-2.748,3.379-2.748q17.52,0,35.04-.007c3.047,0,2.593.395,2.626-2.582.017-1.508,0-1.511-1.577-1.511q-13.887,0-27.774,0c-1.961,0-2.563-.543-2.514-2.484.027-1.055-.353-1.336-1.357-1.32-3.163.052-6.329.062-9.492,0-1.123-.023-1.468.319-1.461,1.455C137.085,174.53,137.064,182.421,137.064,190.311Z"
    transform="translate(-132.885 -160.86)"
    fill="#a8a8a8"
    stroke-width="1"
  />
  </svg>
`;

export const filesSelected = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="61.175"
    height="50.803"
    viewBox="0 0 61.175 50.803"
  >
  <path
    id="files_white"
    d="M161.48,211.162q-12.6,0-25.195,0c-2.373,0-2.9-.527-2.9-2.881q0-22.089,0-44.179c0-2.152.561-2.733,2.679-2.736q7.148-.011,14.3,0c2.058,0,2.675.579,2.623,2.648-.027,1.056.347,1.316,1.353,1.313,9.3-.032,18.593-.02,27.89-.018,2.265,0,2.8.545,2.819,2.85.008,1.133.064,2.27-.015,3.4-.082,1.152.281,1.637,1.5,1.56,1.478-.094,2.967-.031,4.451-.019,2,.017,2.74.75,2.546,2.729-.376,3.843-.829,7.678-1.255,11.516q-.879,7.907-1.766,15.814c-.217,1.938-.418,3.878-.676,5.811-.228,1.711-.84,2.192-2.691,2.193Q174.312,211.167,161.48,211.162Zm.387-3.844c7.654,0,15.308-.014,22.962.016,1.051,0,1.506-.257,1.6-1.415.241-3.069.642-6.126.981-9.188q1.016-9.185,2.037-18.369c.173-1.544.217-1.421-1.376-1.422q-21.2-.008-42.409,0c-1.892,0-1.881,0-2.253,1.819q-2.784,13.584-5.579,27.165c-.286,1.393-.294,1.4,1.2,1.4Q150.445,207.32,161.867,207.318Zm-24.8-17.007.1.071q1.5-7.27,3.005-14.54c.486-2.377.917-2.748,3.379-2.748q17.52,0,35.04-.007c3.047,0,2.593.395,2.626-2.582.017-1.508,0-1.511-1.577-1.511q-13.887,0-27.774,0c-1.961,0-2.563-.543-2.514-2.484.027-1.055-.353-1.336-1.357-1.32-3.163.052-6.329.062-9.492,0-1.123-.023-1.468.319-1.461,1.455C137.085,174.53,137.064,182.421,137.064,190.311Z"
    transform="translate(-132.885 -160.86)"
    fill="#00b397"
    stroke-width="1"
  />
  </svg>
`;

export const progressBarGray = `
  <svg
    class="gray-bar"
    xmlns="http://www.w3.org/2000/svg"
    width="33.75"
    height="35.25"
    viewBox="0 0 33.75 35.25"
  >
    <g transform="translate(20.75)" fill="none">
      <path d="M0,0H13L-7.75,35.25h-13Z" stroke="none" />
      <path
        d="M 0.5717449188232422 1 L -19.00094985961914 34.25 L -8.321744918823242 34.25 L 11.25094985961914 1 L 0.5717449188232422 1 M 0 0 L 13 0 L -7.75 35.25 L -20.75 35.25 L 0 0 Z"
        stroke="none"
        fill="var(--color-progress-bar)"
      />
    </g>
  </svg>
`;

export const progressBarGreen = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33.75"
    height="35.25"
    viewBox="0 0 33.75 35.25"
    fill="#00b397"
  >
    <path fill="#00b397" d="M0,0H13L-7.75,35.25h-13Z" transform="translate(20.75)" />
  </svg>
`;

export const mobSubjects = `
  <svg
    id="disciplinas-select"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="38.093"
    height="29.524"
    viewBox="0 0 38.093 29.524"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
      </linearGradient>
    </defs>
    <path
      id="Caminho_249"
      data-name="Caminho 249"
      d="M337.687,286.651l.093,20.671h11.477l.365-1.331,2.523-1.332h6.587l2.242.21,1.471,2.312.007.14h11.275V286.651Zm18.1,15.182a3.994,3.994,0,1,1,3.994-3.994A3.994,3.994,0,0,1,355.789,301.833Z"
      transform="translate(-336.776 -285.717)"
      fill="transparent"
    />
    <g id="Grupo_147" data-name="Grupo 147">
      <path
        id="Caminho_235"
        data-name="Caminho 235"
        d="M372.976,308.395a4.948,4.948,0,1,0,4.949-4.948h0A4.954,4.954,0,0,0,372.976,308.395Zm7.941,0a2.992,2.992,0,1,1-2.993-2.992h0A3,3,0,0,1,380.917,308.395Z"
        transform="translate(-358.877 -296.236)"
        fill="var(--color-border-buttons-off-mob-menu)"
      />
      <path
        id="Caminho_236"
        data-name="Caminho 236"
        d="M380.714,339.948l-.433-4.824a4.411,4.411,0,0,0-4.257-3.749h-6.879a4.418,4.418,0,0,0-4.26,3.785l-.43,4.788a.977.977,0,0,0,.36.848,13.532,13.532,0,0,0,15.54,0A.979.979,0,0,0,380.714,339.948Zm-8.129,1.345a11.639,11.639,0,0,1-6.131-1.736l.375-4.185a2.432,2.432,0,0,1,2.316-2.04h6.879a2.426,2.426,0,0,1,2.312,2l.378,4.222A11.633,11.633,0,0,1,372.585,341.293Z"
        transform="translate(-353.538 -313.727)"
        fill="var(--color-border-buttons-off-mob-menu)"
      />
      <path
        id="Caminho_237"
        data-name="Caminho 237"
        d="M335.25,285.129v20.629a.978.978,0,0,0,.978.978h6.8a.978.978,0,1,0,0-1.956h-5.828V286.107h34.181V304.78h-6.275a.978.978,0,0,0,0,1.956h7.253a.978.978,0,0,0,.978-.978h0V285.129a.978.978,0,0,0-.978-.978H336.228a.978.978,0,0,0-.978.978Z"
        transform="translate(-335.25 -284.151)"
        fill="var(--color-border-buttons-off-mob-menu)"
      />
    </g>
  </svg>
`;

export const mobSubjectsSelected = `
  <svg
    id="disciplinas-select"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="38.093"
    height="29.524"
    viewBox="0 0 38.093 29.524"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#00b65b" />
        <stop offset="1" stop-color="#00b397" />
      </linearGradient>
    </defs>
    <path
      id="Caminho_249"
      data-name="Caminho 249"
      d="M337.687,286.651l.093,20.671h11.477l.365-1.331,2.523-1.332h6.587l2.242.21,1.471,2.312.007.14h11.275V286.651Zm18.1,15.182a3.994,3.994,0,1,1,3.994-3.994A3.994,3.994,0,0,1,355.789,301.833Z"
      transform="translate(-336.776 -285.717)"
      fill="url(#linear-gradient)"
    />
    <g id="Grupo_147" data-name="Grupo 147">
      <path
        id="Caminho_235"
        data-name="Caminho 235"
        d="M372.976,308.395a4.948,4.948,0,1,0,4.949-4.948h0A4.954,4.954,0,0,0,372.976,308.395Zm7.941,0a2.992,2.992,0,1,1-2.993-2.992h0A3,3,0,0,1,380.917,308.395Z"
        transform="translate(-358.877 -296.236)"
      />
      <path
        id="Caminho_236"
        data-name="Caminho 236"
        d="M380.714,339.948l-.433-4.824a4.411,4.411,0,0,0-4.257-3.749h-6.879a4.418,4.418,0,0,0-4.26,3.785l-.43,4.788a.977.977,0,0,0,.36.848,13.532,13.532,0,0,0,15.54,0A.979.979,0,0,0,380.714,339.948Zm-8.129,1.345a11.639,11.639,0,0,1-6.131-1.736l.375-4.185a2.432,2.432,0,0,1,2.316-2.04h6.879a2.426,2.426,0,0,1,2.312,2l.378,4.222A11.633,11.633,0,0,1,372.585,341.293Z"
        transform="translate(-353.538 -313.727)"
      />
      <path
        id="Caminho_237"
        data-name="Caminho 237"
        d="M335.25,285.129v20.629a.978.978,0,0,0,.978.978h6.8a.978.978,0,1,0,0-1.956h-5.828V286.107h34.181V304.78h-6.275a.978.978,0,0,0,0,1.956h7.253a.978.978,0,0,0,.978-.978h0V285.129a.978.978,0,0,0-.978-.978H336.228a.978.978,0,0,0-.978.978Z"
        transform="translate(-335.25 -284.151)"
      />
    </g>
  </svg>
`;

export const mobMedias = `
  <svg
    id="midias-select"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="38.072"
    height="28.122"
    viewBox="0 0 38.072 28.122"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
      </linearGradient>
    </defs>
    <path
      id="Caminho_248"
      data-name="Caminho 248"
      d="M455.609,286.275v26.067h36.017V286.275Zm15.515,18.5-.14-10.861,7.778,5.4Z"
      transform="translate(-454.581 -285.388)"
      fill="transparent"
    />
    <g id="Grupo_146" data-name="Grupo 146" transform="translate(0 0)">
      <path
        id="Caminho_232"
        data-name="Caminho 232"
        class="btns-path"
        d="M504,310.116l-7.948-5.317a1,1,0,0,0-1.551.829v10.634a1,1,0,0,0,1.551.829L504,311.773A1,1,0,0,0,504,310.116Zm-7.505,4.279v-6.9l5.158,3.451Z"
        transform="translate(-478.939 -296.883)"
        fill="var(--color-border-buttons-off-mob-menu)"
      />
      <g id="Grupo_135" data-name="Grupo 135" transform="translate(0 0)">
        <path
          id="Caminho_233"
          data-name="Caminho 233"
          class="btns-path"
          d="M489.9,312.023H453.887A1.028,1.028,0,0,1,452.859,311V284.929a1.028,1.028,0,0,1,1.028-1.028H489.9a1.028,1.028,0,0,1,1.028,1.028V311A1.028,1.028,0,0,1,489.9,312.023Zm-34.989-2.055h33.961V285.956H454.914Z"
          transform="translate(-452.859 -283.901)"
          fill="var(--color-border-buttons-off-mob-menu)"
        />
      </g>
    </g>
  </svg>
`;

export const mobMediasSelected = `
  <svg
    id="midias-select"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="38.072"
    height="28.122"
    viewBox="0 0 38.072 28.122"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#00b65b" />
        <stop offset="1" stop-color="#00b397" />
      </linearGradient>
    </defs>
    <path
      id="Caminho_248"
      data-name="Caminho 248"
      d="M455.609,286.275v26.067h36.017V286.275Zm15.515,18.5-.14-10.861,7.778,5.4Z"
      transform="translate(-454.581 -285.388)"
      fill="url(#linear-gradient)"
    />
    <g id="Grupo_146" data-name="Grupo 146" transform="translate(0 0)">
      <path
        id="Caminho_232"
        data-name="Caminho 232"
        d="M504,310.116l-7.948-5.317a1,1,0,0,0-1.551.829v10.634a1,1,0,0,0,1.551.829L504,311.773A1,1,0,0,0,504,310.116Zm-7.505,4.279v-6.9l5.158,3.451Z"
        transform="translate(-478.939 -296.883)"

      />
      <g id="Grupo_135" data-name="Grupo 135" transform="translate(0 0)">
        <path
          id="Caminho_233"
          data-name="Caminho 233"
          d="M489.9,312.023H453.887A1.028,1.028,0,0,1,452.859,311V284.929a1.028,1.028,0,0,1,1.028-1.028H489.9a1.028,1.028,0,0,1,1.028,1.028V311A1.028,1.028,0,0,1,489.9,312.023Zm-34.989-2.055h33.961V285.956H454.914Z"
          transform="translate(-452.859 -283.901)"
        />
      </g>
    </g>
  </svg>
`;

export const mobFiles = `
  <svg
    id="arquivos-select"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="33.649"
    height="28.54"
    viewBox="0 0 33.649 28.54"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
      </linearGradient>
    </defs>
    <path
      id="Caminho_251"
      data-name="Caminho 251"
      d="M139.575,287.588l1.892.14,2.312,4.2,1.542.981,13.314-.07,1.822.28,1.331,2.382-.21,16.957-1.542,1.892-26.7-.07-3.153-.561-.561-1.892.28-21.022.14-1.822,1.472-1.331Z"
      transform="translate(-128.962 -286.638)"
      fill="transparent"
    />
    <g id="Grupo_149" data-name="Grupo 149" transform="translate(0)">
      <g id="Grupo_143" data-name="Grupo 143" transform="translate(0)">
        <path
          id="Caminho_246"
          data-name="Caminho 246"
          d="M158.508,290.032H144.129a1,1,0,0,1-.919-.61l-1.073-2.546a2.985,2.985,0,0,0-2.757-1.83h-8.54a3,3,0,0,0-2.991,2.991V310.6a2.994,2.994,0,0,0,2.991,2.991h27.667A2.994,2.994,0,0,0,161.5,310.6V293.024A2.994,2.994,0,0,0,158.508,290.032Zm1,20.563a1,1,0,0,1-1,1H130.841a1,1,0,0,1-1-1V288.038a1,1,0,0,1,1-1h8.54a1,1,0,0,1,.919.61l1.073,2.547a2.986,2.986,0,0,0,2.757,1.829h14.378a1,1,0,0,1,1,1Z"
          transform="translate(-127.85 -285.047)"
          fill="var(--color-border-buttons-off-mob-menu)"
          />
      </g>
      <g
        id="Grupo_144"
        data-name="Grupo 144"
        transform="translate(16.873 1.16)"
      >
        <path
          id="Caminho_247"
          data-name="Caminho 247"
          d="M188.416,290.206H174.028a1.028,1.028,0,1,1,0-2.055h14.388a1.028,1.028,0,0,1,0,2.055Z"
          transform="translate(-173 -288.151)"
          fill="var(--color-border-buttons-off-mob-menu)"
          />
      </g>
    </g>
  </svg>
`;

export const mobFilesSelected = `
  <svg
    id="arquivos-select"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="33.649"
    height="28.54"
    viewBox="0 0 33.649 28.54"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#00b65b" />
        <stop offset="1" stop-color="#00b397" />
      </linearGradient>
    </defs>
    <path
      id="Caminho_251"
      data-name="Caminho 251"
      d="M139.575,287.588l1.892.14,2.312,4.2,1.542.981,13.314-.07,1.822.28,1.331,2.382-.21,16.957-1.542,1.892-26.7-.07-3.153-.561-.561-1.892.28-21.022.14-1.822,1.472-1.331Z"
      transform="translate(-128.962 -286.638)"
      fill="url(#linear-gradient)"
    />
    <g id="Grupo_149" data-name="Grupo 149" transform="translate(0)">
      <g id="Grupo_143" data-name="Grupo 143" transform="translate(0)">
        <path
          id="Caminho_246"
          data-name="Caminho 246"
          d="M158.508,290.032H144.129a1,1,0,0,1-.919-.61l-1.073-2.546a2.985,2.985,0,0,0-2.757-1.83h-8.54a3,3,0,0,0-2.991,2.991V310.6a2.994,2.994,0,0,0,2.991,2.991h27.667A2.994,2.994,0,0,0,161.5,310.6V293.024A2.994,2.994,0,0,0,158.508,290.032Zm1,20.563a1,1,0,0,1-1,1H130.841a1,1,0,0,1-1-1V288.038a1,1,0,0,1,1-1h8.54a1,1,0,0,1,.919.61l1.073,2.547a2.986,2.986,0,0,0,2.757,1.829h14.378a1,1,0,0,1,1,1Z"
          transform="translate(-127.85 -285.047)"
        />
      </g>
      <g
        id="Grupo_144"
        data-name="Grupo 144"
        transform="translate(16.873 1.16)"
      >
        <path
          id="Caminho_247"
          data-name="Caminho 247"
          d="M188.416,290.206H174.028a1.028,1.028,0,1,1,0-2.055h14.388a1.028,1.028,0,0,1,0,2.055Z"
          transform="translate(-173 -288.151)"
        />
      </g>
    </g>
  </svg>
`;

export const mobNotes = `
  <svg
    id="anotacoes-select"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="35.503"
    height="29.299"
    viewBox="0 0 35.503 29.299"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
      </linearGradient>
    </defs>
    <path
      id="Caminho_250"
      data-name="Caminho 250"
      d="M260.25,299.335V287.213H235.375V313.63l.841.841H259.9l.561-.911v-7.708l-3.013,2.943-3.5.14.35-3.924L257.378,302Z"
      transform="translate(-234.301 -285.901)"
      fill="transparent"
    />
    <g id="Grupo_148" data-name="Grupo 148" transform="translate(0 0)">
      <g id="Grupo_140" data-name="Grupo 140" transform="translate(0 0)">
        <path
          id="Caminho_239"
          data-name="Caminho 239"
          d="M262.186,298.3H248.172a.972.972,0,0,0,0,1.943h14.014a.965.965,0,0,0,.972-.972A.989.989,0,0,0,262.186,298.3Z"
          transform="translate(-241.706 -292.845)"
          fill="var(--color-border-buttons-off-mob-menu)"
        />
        <path
          id="Caminho_240"
          data-name="Caminho 240"
          d="M263.158,313.873a.965.965,0,0,0-.972-.972H248.172a.972.972,0,0,0,0,1.943h14.014A.965.965,0,0,0,263.158,313.873Z"
          transform="translate(-241.706 -301.988)"
          fill="var(--color-border-buttons-off-mob-menu)"
        />
        <path
          id="Caminho_241"
          data-name="Caminho 241"
          d="M259.421,328.573l.972-.972H248.172a.989.989,0,0,0-.972.972.965.965,0,0,0,.972.972h10.576A3.735,3.735,0,0,1,259.421,328.573Z"
          transform="translate(-241.706 -311.195)"
          fill="var(--color-border-buttons-off-mob-menu)"
        />
        <path
          id="Caminho_242"
          data-name="Caminho 242"
          d="M248.172,342.3a.972.972,0,1,0,0,1.943h10.277a3,3,0,0,1-.224-1.383l.037-.523h-10.09Z"
          transform="translate(-241.706 -320.401)"
          fill="var(--color-border-buttons-off-mob-menu)"
        />
        <path
          id="Caminho_243"
          data-name="Caminho 243"
          d="M257.5,308.777v2.317h-23.1V285.607h23.1v8.184l1.906-1.906V285.2a1.5,1.5,0,0,0-1.495-1.495H233.995A1.5,1.5,0,0,0,232.5,285.2v26.31A1.476,1.476,0,0,0,233.995,313H257.95a1.5,1.5,0,0,0,1.495-1.495v-4.6l-1.719,1.719A1.166,1.166,0,0,1,257.5,308.777Z"
          transform="translate(-232.5 -283.701)"
          fill="var(--color-border-buttons-off-mob-menu)"
        />
        <path
          id="Caminho_244"
          data-name="Caminho 244"
          d="M298.422,305.8l-2.653-2.653a1.5,1.5,0,0,0-1.084-.448h0a1.5,1.5,0,0,0-1.084.448l-11.174,11.174a1.571,1.571,0,0,0-.448.972l-.187,2.84a1.6,1.6,0,0,0,.448,1.2,1.5,1.5,0,0,0,1.084.448h.112l2.84-.187a1.57,1.57,0,0,0,.972-.448l11.174-11.174a1.534,1.534,0,0,0,0-2.168Zm-12.407,11.884-2.242.149.15-2.242,8.259-8.259,2.13,2.13Zm9.6-9.6-2.13-2.13,1.2-1.2,2.13,2.13Z"
          transform="translate(-263.368 -295.6)"
          fill="var(--color-border-buttons-off-mob-menu)"
        />
      </g>
    </g>
  </svg>
`;

export const mobNotesSelected = `
  <svg
    id="anotacoes-select"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="35.503"
    height="29.299"
    viewBox="0 0 35.503 29.299"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#00b65b" />
        <stop offset="1" stop-color="#00b397" />
      </linearGradient>
    </defs>
    <path
      id="Caminho_250"
      data-name="Caminho 250"
      d="M260.25,299.335V287.213H235.375V313.63l.841.841H259.9l.561-.911v-7.708l-3.013,2.943-3.5.14.35-3.924L257.378,302Z"
      transform="translate(-234.301 -285.901)"
      fill="url(#linear-gradient)"
    />
    <g id="Grupo_148" data-name="Grupo 148" transform="translate(0 0)">
      <g id="Grupo_140" data-name="Grupo 140" transform="translate(0 0)">
        <path
          id="Caminho_239"
          data-name="Caminho 239"
          d="M262.186,298.3H248.172a.972.972,0,0,0,0,1.943h14.014a.965.965,0,0,0,.972-.972A.989.989,0,0,0,262.186,298.3Z"
          transform="translate(-241.706 -292.845)"
        />
        <path
          id="Caminho_240"
          data-name="Caminho 240"
          d="M263.158,313.873a.965.965,0,0,0-.972-.972H248.172a.972.972,0,0,0,0,1.943h14.014A.965.965,0,0,0,263.158,313.873Z"
          transform="translate(-241.706 -301.988)"
        />
        <path
          id="Caminho_241"
          data-name="Caminho 241"
          d="M259.421,328.573l.972-.972H248.172a.989.989,0,0,0-.972.972.965.965,0,0,0,.972.972h10.576A3.735,3.735,0,0,1,259.421,328.573Z"
          transform="translate(-241.706 -311.195)"
        />
        <path
          id="Caminho_242"
          data-name="Caminho 242"
          d="M248.172,342.3a.972.972,0,1,0,0,1.943h10.277a3,3,0,0,1-.224-1.383l.037-.523h-10.09Z"
          transform="translate(-241.706 -320.401)"
        />
        <path
          id="Caminho_243"
          data-name="Caminho 243"
          d="M257.5,308.777v2.317h-23.1V285.607h23.1v8.184l1.906-1.906V285.2a1.5,1.5,0,0,0-1.495-1.495H233.995A1.5,1.5,0,0,0,232.5,285.2v26.31A1.476,1.476,0,0,0,233.995,313H257.95a1.5,1.5,0,0,0,1.495-1.495v-4.6l-1.719,1.719A1.166,1.166,0,0,1,257.5,308.777Z"
          transform="translate(-232.5 -283.701)"
        />
        <path
          id="Caminho_244"
          data-name="Caminho 244"
          d="M298.422,305.8l-2.653-2.653a1.5,1.5,0,0,0-1.084-.448h0a1.5,1.5,0,0,0-1.084.448l-11.174,11.174a1.571,1.571,0,0,0-.448.972l-.187,2.84a1.6,1.6,0,0,0,.448,1.2,1.5,1.5,0,0,0,1.084.448h.112l2.84-.187a1.57,1.57,0,0,0,.972-.448l11.174-11.174a1.534,1.534,0,0,0,0-2.168Zm-12.407,11.884-2.242.149.15-2.242,8.259-8.259,2.13,2.13Zm9.6-9.6-2.13-2.13,1.2-1.2,2.13,2.13Z"
          transform="translate(-263.368 -295.6)"
        />
      </g>
    </g>
  </svg>
`;
