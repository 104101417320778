//#region Imports

import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, Observable } from 'rxjs';

//#endregion

export type PWAInstallEvent = Event & {
  userChoice: Promise<{ outcome: 'accepted' | any }>;
  prompt: () => void;
};

/**
 * A classe que representa o serviço que lida com o PWA
 */
@Injectable({
  providedIn: 'root',
})
export class PWAService {
  //#region Constructor

  /**
   * Construtor padrão
   */
  constructor(private readonly swUpdate: SwUpdate) {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();

      this.promptEvent$.next(true);
      this.promptEvent = event as PWAInstallEvent;
    });
  }

  //#endregion

  //#region Private Properties

  /**
   * A referência para o evento de instalar o PWA
   */
  private promptEvent?: PWAInstallEvent;

  /**
   * O Behavior Subject que lida com a notificação do promptEvent
   */
  private promptEvent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  //#endregion

  //#region LifeCycle Events

  /**
   * Método executado ao iniciar o componente
   */
  public async initializePWA(): Promise<void> {
    this.swUpdate.available.subscribe(async () => {
      await this.swUpdate.activateUpdate();

      window.location.reload();
    });

    this.swUpdate.unrecoverable.subscribe(() => {
      window.location.reload();
    });

    if (!this.swUpdate.isEnabled) return;

    await this.swUpdate.checkForUpdate();
  }

  //#endregion

  //#region Public Methods

  /**
   * Método que instala o PWA no celular do usuário
   */
  public async promptToInstallPWA(): Promise<void> {
    if (!this.promptEvent) return;

    this.promptEvent.prompt();

    const result = await this.promptEvent.userChoice;

    if (result.outcome !== 'accepted') this.promptEvent?.prompt();

    this.promptEvent = void 0;
    this.promptEvent$.next(false);
  }

  /**
   * Método que retorna um observable avisando se o aplicativo pode ser instalado ou não
   */
  public itCanBeInstalled$(): Observable<boolean> {
    return this.promptEvent$.asObservable();
  }

  //#endregion
}
