//#region Imports

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from '../../environments/environment';
import { StorageService } from '../services/storage/storage.service';

import { UserService } from '../services/user/user.service';

//#endregion

/**
 * A classe que representa o guard que lida com a autenticação do APP
 */
@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  //#region Constructor

  /**
   * Construtor padrão
   */
  constructor(
    private readonly auth: UserService,
    private readonly router: Router,
    private readonly storage: StorageService
  ) {}

  //#endregion

  /**
   * Método que diz se deve ativar a rota ou não
   */
  public async canActivate(
    route: ActivatedRouteSnapshot,
    _: RouterStateSnapshot
  ) {
    await this.auth.setupAuthentication();

    const { unprotectedRoute, protectedRoute, routeToRedirect } = route.data;

    const { success } = await this.storage.getItem(environment.keys.token);
    const hasToken = !!success;

    if (hasToken && protectedRoute) return true;

    if (!hasToken && unprotectedRoute) return true;

    return void (await this.router.navigate([routeToRedirect], {
      replaceUrl: true,
    }));
  }
}
