/**
 * Método que espera alguns segundos para continuar executando
 */
export async function delay(milliseconds: number): Promise<void> {
  return await new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/**
 * Método que retorna o valor com um zero a esquerda se necessário
 *
 * @param num O número que será formatado
 */
export function getLeftPad(num: number): string {
  return num <= 9 ? `0${num}` : `${num}`;
}

/**
 * Método que retorna o valor sem referências
 *
 * @param mock O valor a ser transformado
 */
export function noRef<T>(mock: T): T {
  if (['string', 'number'].includes(typeof mock)) return mock;

  return JSON.parse(JSON.stringify(mock));
}

/**
 * Método que retorna a mensagem erro
 *
 * @param error As informações de erro
 */
export function getErrorMessage({ status, error, message }: any): string {
  if (status === 503)
    return (
      error?.message ||
      'Ocorreu um erro inesperado, por favor, experimente atualizar a página e tentar novamente.'
    );

  if (status >= 500 && status <= 599)
    return 'Ocorreu um erro inesperado, por favor, experimente atualizar a página e tentar novamente.';

  if (!error) {
    if (Array.isArray(message)) return message[0];

    return (
      message ||
      'Ocorreu um erro inesperado, por favor, experimente atualizar a página e tentar novamente.'
    );
  }

  if (!Array.isArray(error.message)) {
    if (typeof error.message === 'string' && error.message.includes('Cannot'))
      return 'A rota especificada não foi encontrada, por favor, contate os administradores se o erro persistir.';

    return (
      error.message ||
      'Ocorreu um erro inesperado, por favor, experimente atualizar a página e tentar novamente.'
    );
  }

  if (error.message.every((message) => typeof message === 'string'))
    return error.message[0];

  // @ts-ignore
  return error.message
    .map(({ constraints }) => (constraints && Object.values(constraints)) || [])
    .reduce((acc, actual) => [...acc, ...actual] as string[])[0];
}

/***
 * Método que transforma segundos em horas
 *
 * @param timeInSeconds O tempo total em segundos
 */
export function convertSecondsToHour(timeInSeconds: number): string {
  const hours = Math.floor(timeInSeconds / (60 * 60));
  let minutes = Math.floor(timeInSeconds / 60);
  let slackMinutes = Math.floor(minutes / 60);
  let restHours = timeInSeconds % (60 * 60);

  minutes = minutes - 60 * slackMinutes;
  restHours %= 60;
  const seconds = Math.ceil(restHours);

  return `${hours}:${minutes}:${seconds}`;
}

/***
 * Método que transforma o horário em segundos
 *
 * @param time O tempo no formato (HH:MM:SS)
 */
export function convertHourToSeconds(time: string): number {
  var currentTime = time.split(':'); // split it at the colons

  return +currentTime[0] * 60 * 60 + +currentTime[1] * 60 + +currentTime[2];
}
